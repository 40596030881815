import Navigator from "../components/Navigator";
import "../App.css";

function Home() {
  return (
    <>
      <Navigator />
      <div className="Main-Div"></div>;
      <h1 className="Page-Header">Portfolio</h1>
    </>
  );
}

export default Home;
