import exp from "constants";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";

type Props = {
  HeaderText: string;
  PText: string;
};

const style = {
  background:
    "url('https://i.pinimg.com/originals/14/1f/5b/141f5be1f9ae70c47f376dbb63d199f8.jpg')",
};

function Card(props: Props) {
  return (
    <div className="card rgb">
      <div className="card-image card2"></div>
      <div className="card-text card2">
        <span className="date">08/28/2024</span>
        <h2>{props.HeaderText}</h2>
        <p>{props.PText}</p>
      </div>
    </div>
  );
}

export default Card;
