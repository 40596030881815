import Navigator from "../components/Navigator";
import "../App.css";
import "../style/Cards.css";

import Card from "../components/Card";

function Home() {
  return (
    <>
      <Navigator />
      <div className="Main-Div"></div>
      <div className="card-holder">
        <Card
          HeaderText={"Welcome!"}
          PText={
            "This site is a portfolio/shop directly purposed to advertise and sell my products/services!"
          }
        ></Card>
        <Card
          HeaderText={"About me!"}
          PText={
            "Click the about me button in the navigator to learn a little more about me and my experience!"
          }
        ></Card>
        <Card
          HeaderText={"My portfolio!"}
          PText={
            "Click the portfolio button in the navigator to see some of the work i've done to figure out if my services are right for you!"
          }
        ></Card>
        <Card
          HeaderText={"My portfolio!"}
          PText={
            "Click the portfolio button in the navigator to see some of the work i've done to figure out if my services are right for you!"
          }
        ></Card>
      </div>
    </>
  );
}

export default Home;
